<template>
    <div class="surface-0 flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
        <Toast/>
        <div class="grid justify-content-center p-2 lg:p-0" style="min-width:80%">
            <div class="col-12 mt-5 xl:mt-0 text-center">
                <a href="/">
                    <img src="/images/contablus1.png" alt="Sakai logo" class="mb-5" style="width:200px;">
                </a>
            </div>
            <div class="col-12 xl:col-6 creds-container" style="border-radius:30px; padding:0.3rem; background-color: #f7ab47;">
                <div class="h-full w-full m-0 py-7 px-4" style="border-radius:27px; background-color: white;">                
                    <div class="w-full md:w-10 mx-auto">
                        <div class="field">
                            <label for="email1" class="block text-900 text-xl font-medium mb-2">Correo</label>
                            <InputText id="email1" v-model="form.email" type="text" :class="{'p-invalid': this.usernameError, 'w-full': true, 'mb-3': true}" placeholder="Correo" style="padding:1rem;" @change="usernameChanged()"/>
                            <small class="p-invalid" v-if="this.usernameError" style="margin-bottom: 20px;">El usuario/correo ingresado no existe.</small>    
                        </div>
                        <div class="field">
                            <label for="password1" class="block text-900 font-medium text-xl mb-2">Contraseña</label>
                            <Password id="password1" v-model="form.password" placeholder="Contraseña" :feedback="false" :toggleMask="true" :class="{'p-invalid': this.passwordError, 'w-full': true, 'mb-3': true}" inputClass="w-full" inputStyle="padding:1rem" @change="passwordChanged()"></Password>
                            <small class="p-invalid" v-if="this.passwordError">La contraseña ingresada es incorrecta.</small>
                        </div>
                        <div class="flex align-items-center justify-content-between mb-5">
                            <a class="font-medium no-underline ml-2 text-right cursor-pointer" style="color: #27285f">¿Olvido su contraseña?</a>
                        </div>
                        <Button label="Iniciar sesión" class="w-full p-3 text-xl" style="background: #27285f; border: 0px;" @click="login()"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import httpRequest from '../service/httpRequest';

export default {
    data() {
        return {
            form: {
                email: '',
                password: ''
            },
            usernameError: false,
            passwordError: false,
        }
    },
    computed: {
        logoColor() {
            if (this.$appState.darkTheme) return 'white';
            return 'dark';
        }
    },
    methods: {
        async login() {
            let error = this.checkForm();
            if (error !== null) {
                 this.$toast.add({severity:'error', summary: 'Error', detail: error, life: 3000});
            } else {
                let response = await httpRequest.login(this.form.email, this.form.password);
                if (response.status == 200) {
                    await this.$store.dispatch('Login', response.data.user);
                    await this.$store.dispatch('setDefaultActivity', response.data.defaultActivity);
                    this.$router.push('/dashboard');
                } else if (response.status == 500) { // Wrong username/email
                    this.usernameError = true;
                } else if (response.status == 501) { // Wrong password
                    this.passwordError = true;
                }
            }
        },
        checkForm() {
            var error = null;
            if (this.form.email === null || this.form.email === "") {
                error = "Por favor ingrese su nombre de usuario o correo";
            } else if (this.form.password === null || this.form.password === "") {
                error = "Por favor ingrese su contraseña";
            }
            return error;
        },
        usernameChanged() {
            this.usernameError = false;
        },
        passwordChanged() {
            this.passwordError = false;
        },
    }
}
</script>

<style scoped>
.pi-eye {
    transform:scale(1.6);
    margin-right: 1rem;
}

.pi-eye-slash {
    transform:scale(1.6);
    margin-right: 1rem;
}

@media screen and (max-width: 760px) {
    .creds-container {
        width: 90%;
    }
}
</style>